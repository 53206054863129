.component-post-row {

    margin: 6px;

    &.hidden {
        display: none !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .row-content.loading {
        opacity: 0.3;
        pointer-events: none;
    }

    .top-row {
        font-size: 0.9em;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .rank {
            width: 18px;
            min-width: 18px;
            color: #999999;
        }

        .upvote {
            width: 14px;
            img {
                margin-bottom: 1px;
                width: 10px;
                object-fit: contain;
                object-position: bottom;
            }
            img:hover {
                cursor: pointer;
            }

        }

        .title {

            .website a {
                font-size: 0.8em;
                margin-left: 6px;
                color: #666;
                text-decoration: none;
            }
            .website a:hover {
                text-decoration: underline;
            }
        }
    }

    .bottom-row {
        padding-left: 15px;
        font-size: 0.8em;
        color: #666;

        &.has-rank {
            padding-left: 30px; 
        }

        .divider {
            margin: 0 6px;
        }

        a {
            color: #666;
        }

        a:hover, .unvote:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    @media (max-width: 550px) {


        .top-row {
            margin-top: 12px;
            .title {
                a {
                    display: block;
                }
    
                .website a {
                    margin-left: 0;
                }
            }
        }

        .bottom-row {
            font-size: 0.75em;
        }

    }

}
