@import "../../resources/style/variables.scss";

$footer-height: 170px; // hardcoded
$navbar-margin-top: 10px;
$body-padding-bottom: 4rem;

.view-post-detail-page {
    background-color: lighten($primary-color, 64%);

    .post-detail-container {
        min-height: calc(100vh - #{$footer-height} - #{$navbar-height} - #{$navbar-margin-top} - #{$body-padding-bottom});
        box-sizing: border-box;
    }

    &.hidden {
        display: none;
    }

    .form-group {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 0;

        textarea {
            box-sizing: border-box;
            width: 100%;
        }

        &.submit {
            margin-top: 0;

            input {
                width: 160px;
            }

            input:disabled {
                cursor: not-allowed;
            }
        }
    }

    .comment-list-container {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .error-container {
        margin-left: 20px;
        margin-right: 20px;
    }
}
