
@import "./variables.scss";

body {
    margin: 0 auto;
    max-width: 80vw;
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    padding: 0 1em 4em 1em;
    color: $body-color;
    background-color: $body-bg;
}

h1,
h2,
strong {
  color: $strong-color;
}

h2 {
    margin-top: 1em;
    padding-top: 1em;
}

a {
    color: $primary-color;
}

.tagline {
    margin-bottom: 1.5em;
    font-size: 0.9em;
    font-style: italic;
    color: lighten($body-color, 20%);
}

.error {
    font-size: 0.8em;
    color: $error-color;
}

.form-group {

    margin-bottom: 20px;

    label {
        display: block;
        font-size: 0.8em;
        text-transform: uppercase;
        -webkit-appearance: none;
    }

    input, textarea {
        width: $input-width;
        outline: none;
        margin-bottom: 5px;
        font-size: 0.8em;
        line-height: 1;
        -webkit-appearance: none;
    }

    textarea {
        height: 140px;
    }

    input[type=text], input[type=password] {
        padding: $input-padding; 
        border: 1px solid #ccc; 
        -webkit-border-radius: 8px;
        border-radius: 1px;
        outline: none;
    }
    
    input[type=text]:focus, input[type=password]:focus {
        border-color:#333;
        border: 1px solid $accent-color; 
    }

    textarea {
        padding: $input-padding; 
        border: 1px solid #ccc; 
        -webkit-border-radius: 8px;
        border-radius: 1px;
        outline: none;
    }
    
    textarea:focus {
        border-color:#333;
        border: 1px solid $accent-color; 
    }

    input[type=submit] {
        width: $input-width + 2 * $input-padding;
        padding: 8px;
        margin-top: 20px;
        background: $primary-color;
        border: 1px solid $primary-color;
        color: white;
        -webkit-border-radius: 1px;
        border-radius: 1px; 
        cursor: pointer;
    }

    input[type=submit]:disabled {
        background: #aaaaaa;
        border: 1px solid #aaaaaa;
    }

    &.has-error {
        margin-bottom: 10px;
    }
}

.error-container {
    :first-child {
        margin-top: 20px
    }
}

.link-group {
    margin-top: 20px;

    p {
        margin: 0;
    }

    a {
        font-size: 0.8em;
    }
}

// on screens smaller 750px with
@media (max-width: 750px) {
    body {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 550px) {
    body {
        background-color: #f3f7fd;
        padding: 0 10px 4em 10px;
        max-width: 100%;
        overflow-x: hidden;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    body::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE and Edge */
    body {
        -ms-overflow-style: none;
    }
}



.site-container {
    // the container between navbar and footer
    min-height: calc(100vh - #{$footer-height} - #{$navbar-height} - #{$navbar-margin-top} - #{$body-padding-bottom});
    box-sizing: border-box;
}