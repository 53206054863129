
$body-bg:  #fcfcfc;
$body-color: #333;
$strong-color: #111;
$primary-color: #164194;
$accent-color: #bcce00;
$error-color: #f30031;

$input-width: 280px;
$input-padding: 8px;

$navbar-height: 30px;

// hardcoded to calculate min height of content views
$footer-height: 170px; 
$navbar-margin-top: 10px;
$body-padding-bottom: 4rem;

$footer-height: 170px; // hardcoded
$navbar-margin-top: 10px;
$body-padding-bottom: 4rem;