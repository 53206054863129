.component-comment-row {

    margin: 12px 6px;

    &.hidden {
        display: none !important;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .top-row {
        font-size: 0.9em;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .upvote {
            width: 14px;

            img {
                margin-bottom: 1px;
                width: 10px;
                object-fit: contain;
                object-position: bottom;
            }
            img:hover {
                cursor: pointer;
            }
        }

        .user, .unvote {
            font-size: 0.8em;
            color: #666;
        }
    }

    .bottom-row {
        padding-left: 15px;
        font-size: 0.8em;
    }

}
