@import "../../resources/style/variables.scss";

$input-width: 320px;

.view-new-post-page {

    .form-group {
        input {
            width: $input-width;
        }
        
        input[type=submit] {
            width: $input-width + 2 * $input-padding;
        }
    }
}
