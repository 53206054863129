@import "../../resources/style/variables.scss";


.view-home-page {
    background-color: lighten($primary-color, 64%);

    .post-list-container{
        box-sizing: border-box;
        padding: 10px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .post-list {
        box-sizing: border-box;
        width: 100%;
    }

    .button-container {
        width: 100%;
        padding: 0 38px;
        margin-top: 20px;

        .button {
            font-size: 0.8em;
            color: black;
            cursor: pointer;
        }
    }

    &.hidden {
        display: none;
    }

}
