// styling specific for the specific component
.view-forgot-password-page {
    .info-container {
        font-size: 0.8em;

        &::before {
            content: "Info: ";
            font-weight: bold;
        }
    }
}
