@import "../resources/style/variables.scss";

.component-footer {
    margin-top: 16px;
    padding: 20px 10%;
    border-top: 2px solid $primary-color;

    font-size: 0.7em;
    color: #555;
    text-align: center;

    a {
        text-decoration: none;
        color: #555;
    }
    a:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    div {
        margin: 12px 0;
    }

    .divider{
        margin: 0 6px;
    }
}
